import React from 'react';
import {
    Route,
    Redirect,
    RouteChildrenProps,
} from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingComponent from 'components/loading';
import { RootState } from 'state/store';

interface StateProps {
    isAuthenticated: boolean;
    isVerifying: boolean;
}

interface InheritedProps {
    [x: string]: any;
    component: any;
    exact?: boolean;
}

type ProtectedRouteProps = StateProps & InheritedProps & RouteChildrenProps;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    isAuthenticated,
    isVerifying,
    component: Component,
    ...rest
}: ProtectedRouteProps) => {
    const renderComponent: React.FC<any> = (props: any) => {
        const { location } = props;
        if (isVerifying) {
            return <LoadingComponent />;
        }
        if (isAuthenticated) {
            return <Component {...props} />;
        }
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: location },
                }}
            />
        );
    };

    return (
        <Route
            {...rest}
            render={renderComponent}
        />
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
});

export default connect(mapStateToProps)(ProtectedRoute);
