// eslint-disable-next-line
import publicIp from 'public-ip';
import { SignUpDetails } from 'pages/signUp';
import firebase, { database } from './firebaseAuth';

interface Publisher {
    uid: string;
    publisherName: string;
    emailId: string;
    phoneNumber: string;
    ip: string;
    ua: string;
    optIn: boolean;
    optInText?: string | null;
    pid?: string | null;
}

export const createPublisher = async (publisher: SignUpDetails): Promise<any> => {
    const {
        emailId,
        publisherName,
        phoneNumber,
        password,
        optIn,
    } = publisher;
    const userCredential: firebase.auth.UserCredential = await firebase
        .auth().createUserWithEmailAndPassword(emailId, password);

    if (userCredential && userCredential.user) {
        const { uid } = userCredential.user;
        const ip = await publicIp.v4();
        const ref = database.ref(`publishers/${uid}`);
        const publisherInfo: Publisher = {
            uid,
            publisherName,
            emailId,
            phoneNumber: phoneNumber.replace(/[-()\s]/g, ''),
            ip,
            ua: navigator.userAgent,
            optIn,
        };

        if (window && window.location && window.location.origin) {
            const termsAndConditions = `${window.location.origin}/terms_and_conditions`;
            publisherInfo.optInText = `I agree to terms and conditions (${termsAndConditions})`;
        }
        await ref.set(publisherInfo);

        return userCredential.user;
    }

    throw new Error('Error while creating publisher in firebase auth');
};
