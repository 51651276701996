import React from 'react';
import './header.css';

interface HeaderProps {
    emailId: string | null;
    logout: () => void;
}

const Header: React.FC<HeaderProps> = ({ emailId, logout }: HeaderProps) => {
    const onLogout = (): void => {
        logout();
    };

    return (
        <header className="header-wrapper">
            <div className="logo-wrapper fl">
                <img
                    className="fl"
                    src="/images/logo.svg"
                    alt="consumer-research"
                />
                <div className="fl logo-name">Connexus Digital Publisher Dashboard</div>
            </div>
            {
                emailId
                && (
                    <div className="login-name-wrapper fr">
                        <div className="fl login-name">{emailId}</div>
                        <div className="fl logout" onClick={onLogout}>
                            <img
                                className="login-img"
                                src="/images/logout.png"
                                alt="logout"
                            />
                        </div>
                    </div>
                )
            }
        </header>
    );
};

export default Header;
