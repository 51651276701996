import React from 'react';
import './loading.css';

const Loading: React.FC = () => (
    <div className="loading-wrapper container col-xs-12">
        <div className="loading-block">
            <img
                className="loading-img"
                src="/images/loading_rafiki.svg"
                alt="loading"
            />
            <div className="loading-text">
                Loading...
            </div>
        </div>
    </div>
);

export default Loading;
