import axios from 'axios';
import { getFlockUrl } from 'common/urlManager';
import { SignUpDetails } from 'pages/signUp';
import { formatPhoneNumber } from './index';

export const postFlock = async (signUpDetails: SignUpDetails): Promise<void> => {
    try {
        const data = {
            flockml: `<flockml>
            <b>[PublisherService]</b> New Publisher has signed up :memo:<br/>
            <b><em>Publisher Name: </em></b>${signUpDetails.publisherName}<br/>
            <b><em>Email Id: </em></b>${signUpDetails.emailId}<br/>
            <b><em>Phone Number: </em></b>${formatPhoneNumber(signUpDetails.phoneNumber)}<br/>
            <flockml>`,
        };

        await axios.post(getFlockUrl(), data);
    } catch (err) {
        console.error('ERR while sending to flock');
    }
};
