import React from 'react';
import './thankYou.css';

const ThankYou: React.FC = () => (
    <div className="thank-you-wrapper container col-xs-12">
        <div className="row thank-you-block">
            <div className="col-sm-6 thank-you-left">
                <div className="thank-you-txt-large">
                    Thank you for signing up for Connexus Digital publisher account
                </div>
                <div className="thank-you-txt-small">
                    Our representatives will get in touch with you shortly
                </div>
            </div>
            <div className="col-sm-6 thank-you-right">
                <div className="thank-you-illustration">
                    <img src="/images/login-img.png" alt="login-img" />
                </div>
            </div>
        </div>
    </div>
);

export default ThankYou;
