export const isNull = (obj: unknown): boolean => typeof (obj) === 'undefined' || obj === null;

export const isEmptyObj = (obj: unknown | any): boolean => isNull(obj)
    || !(Object.keys(obj).length);

export const isValidEmail = (email: string): boolean => {
    const regex = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;
    return regex.test(email);
};

export const isValidPhoneNumber = (phoneNumber: string): boolean => Boolean(phoneNumber
    && phoneNumber.length >= 10);

export const formatPhoneNumber = (rawPhoneNumber: string): string => {
    const phoneNumber = rawPhoneNumber.replace(/\D/g, '');

    const partA = phoneNumber.substring(0, 3);
    const partB = phoneNumber.substring(3, 6);
    const partC = phoneNumber.substring(6, 11);

    // 2345678  =>  234-567-8
    if (partC) {
        return `(${partA}) ${partB}-${partC}`;
    }
    // 2345 => 234-5
    if (partB) {
        return `${partA}-${partB}`;
    }
    // if partA
    return phoneNumber;
};

export const constructObjectWithValues = <T>(
    obj: { [x: string]: any },
    defaultValue: T,
): { [x: string]: T } => {
    const newObj: { [x: string]: T } = {};
    const keys: string[] = Object.keys(obj);
    keys.forEach((key): void => {
        newObj[key] = defaultValue;
    });
    return newObj;
};

export const executeValidationRules = (
    rules: ((v: any) => string | null)[],
    // eslint-disable-next-line
    value: any,
): string | null => {
    let error: string | null = null;
    if (rules) {
        rules.some((rule): boolean => {
            const err: string | null = rule(value);
            if (err) {
                error = err;
                return true;
            }
            return false;
        });
    }
    return error;
};

interface ExecuteAllValidationRules {
    errorsObj: { [x: string]: string | null };
    hasError: boolean;
}

export const executeAllValidationRules = (
    validationRules: { [x: string]: any },
    values: { [x: string]: any },
): ExecuteAllValidationRules => {
    const errorsObj: { [x: string]: string | null } = {};
    let hasError = false;
    Object.keys(validationRules).forEach((ruleId: string): void => {
        errorsObj[ruleId] = executeValidationRules(validationRules[ruleId], values);
        if (!hasError) {
            hasError = Boolean(errorsObj[ruleId]);
        }
    });
    return { errorsObj, hasError };
};
