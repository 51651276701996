import { LoginCredentials } from 'state/firebaseSlice';

const validationRules = {
    emailId: [
        (value: LoginCredentials): string | null => ((value.emailId && value.emailId.length)
            ? null : 'Email Id is required'),
    ],
    password: [
        (value: LoginCredentials): string | null => ((value.password && value.password.length)
            ? null : 'Password is required'),
    ],
};

export default validationRules;
