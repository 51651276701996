import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { verifyAuth } from './firebaseSlice';

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.REACT_APP_ENV === 'dev',
});

store.dispatch(verifyAuth());

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
