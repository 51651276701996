import React from 'react';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { RootState, AppDispatch } from 'state/store';
import { resetToast as resetToastAction } from 'state/toast';
import './toast.css';

interface StateProps {
    show: boolean;
    message: string;
    type: Color;
}

interface DispatchProps {
    resetToast: () => void;
}

type ToastProps = StateProps & DispatchProps;

const Toast: React.FC<ToastProps> = ({
    show,
    message,
    type,
    resetToast,
}: ToastProps) => {
    const handleClose = (event?: React.SyntheticEvent): void => {
        if (event) {
            event.stopPropagation();
        }
        if (show) {
            resetToast();
        }
    };

    return (
        <Snackbar
            open={show}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Alert
                onClose={handleClose}
                variant="filled"
                severity={type}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    show: state.toast.show,
    message: state.toast.message,
    type: state.toast.type,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    resetToast: (): void => { dispatch(resetToastAction()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
