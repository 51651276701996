import firebase from 'firebase/app';

interface UserAuth {
    setUser: (newUser: firebase.User) => void;
    getToken: () => Promise<string>;
}

const userAuth = (): UserAuth => {
    let user: firebase.User | null = null;
    let token: string | null = null;

    return {
        setUser: (newUser: firebase.User): void => {
            user = newUser;
        },
        getToken: async (): Promise<string> => {
            if (user) {
                token = await user.getIdToken();
                return token;
            }
            throw new Error('No user object found');
        },
    };
};

export default userAuth();
