/* eslint-disable */
import React from 'react';
import './termsAndConditions.css';

const TermsAndConditions: React.FC = () => (
    <div className="iframe-wrapper">
        <div className="container">
            <div className="iframe-content-styling">
                <h1><strong>PUBLISHER TERMS AND CONDITIONS</strong></h1>
                <p>These Terms and Conditions (the &ldquo;<strong>Terms</strong>&rdquo;) govern and are incorporated by reference into the Insertion Order(s) (&ldquo;<strong>IO(s)</strong>&rdquo;) entered into by and between Connexus Digital, Inc (&ldquo;<strong>Connexus</strong>&rdquo;) and the publisher whose name appears on the IO(s) (&ldquo;<strong>Publisher</strong>&rdquo;). These Terms are effective and binding with respect to Publisher as of the date (the &ldquo;<strong>Effective Date</strong>&rdquo;) set forth in the IO first executed by that Publisher. These Terms and any IOs are collectively referred to herein as the &ldquo;<strong>Agreement</strong>.&rdquo;<span className="Apple-converted-space">&nbsp;</span></p>
                <ul>
                    <li><h2><strong>Definitions.</strong></h2>
                        <ul>
                            <li>&ldquo;<strong>Applicable Law(s)</strong>&rdquo; means all applicable laws, rules, regulations, governmental permits, or other binding determinations of any governmental authorities or other authorities having force of law, including but not limited to the Federal Trade Commission Act, the Fair Credit Reporting Act, the Telephone Consumer Protection Act (&ldquo;<strong>TCPA</strong>&rdquo;), the Do Not Call Implementation Act, the Amended Telemarketing Sales Rule (&ldquo;<strong>ATSR</strong>&rdquo;), any applicable banking and consumer credit laws, applicable Federal Trade Commission (&ldquo;<strong>FTC</strong>&rdquo;) rules, regulations and opinions, any applicable laws regulating commercial email, and applicable privacy and data protection laws, rules and regulations, as such acts, laws, rules, regulations and/or opinions may be amended, modified or supplemented from time to time. Applicable Laws also includes, as applicable, the United States Digital Advertising Alliance Self-Regulatory Principles published at <a href="http://www.aboutads.info" target="_blank"><span className="s1">www.aboutads.info</span></a>, the Children&rsquo;s Online Privacy Protection Act, and any or all of the following (a) the IAB Europe EU Framework for Online Behavioural Advertising and the EASA Best Practice Recommendation on Online Behavioral Advertising, which are administered by the European Interactive Digital Advertising Alliance and published at <a href="http://www.edaa.eu"  target="_blank"><span className="s1">www.edaa.eu</span></a>; and (b) the Data Protection Laws.</li>
                            <li>&ldquo;<strong>Creative(s)</strong>&rdquo; means the advertisements and any components or elements thereof provided by Connexus to Publisher including banner ads, emails including the body, header and subject lines, ad copy, contextual ads and/or other content that comprises the Creative. Creative shall also include advertising content created by Connexus which has been approved by Publisher prior to use.</li>
                            <li>&ldquo;<strong>Data Protection Law(s)</strong>&rdquo; means (a) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the Processing of Personal Data and on the free movement of such data (&ldquo;<strong>GDPR</strong>&rdquo;); (b) the EU e-Privacy Directive (Directive 2002/58/EC); (c)&nbsp;California Civil Code, section 1798, et seq. (&ldquo;<strong>CCPA</strong>&rdquo;); and (d)&nbsp;any and all applicable data protection laws made under or pursuant to (a)-(c); in each case as may be amended or superseded from time to time.</li>
                            <li>&ldquo;<strong>Inventory</strong>&rdquo; means the advertising space on the Sites.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li>&ldquo;<strong>Lead(s)</strong>&rdquo; means data, to the extent specified in an applicable IO, that is provided by a consumer either (a) via a Creative; or (b) on any Site.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li>&ldquo;<strong>Native Ad(s)</strong>&rdquo; means an Ad that is harmonious with the surrounding content.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li>&ldquo;<strong>Site(s)</strong>&rdquo; means any website and/or webpage that is owned, operated or controlled by Publisher, or on which Publisher<span className="Apple-converted-space">&nbsp;&nbsp;</span>has a contractual right to serve Creatives.</li>
                            <li>The terms &ldquo;<strong>Data Subjects</strong>&rdquo; and &ldquo;<strong>Personal Data</strong>&rdquo; have the meaning ascribed to such terms in Data Protection Laws.</li>
                        </ul>
                    </li>
                    <li><h2><strong>Services.<span className="Apple-converted-space">&nbsp;</span></strong></h2>
                        <ul>
                            <li>Publisher shall deliver Leads to Connexus in the format specified in the IO(s) (the &ldquo;<strong>Services</strong>&rdquo;).</li>
                            <li>The provisions of this Section 2.2 through 2.5 are applicable only if (a) the IO specifies that the Leads include telephone numbers of consumers (&ldquo;<strong>Consenting User(s)</strong>&rdquo;) who have provided their &ldquo;express written consent&rdquo; (as that term is defined in 47 CFR &sect;64.1200(f)(8); a &ldquo;<strong>TCPA Consent</strong>&rdquo;) to be contacted by or on behalf of Connexus, or (b) the IO specifies a drive-to-site campaign where Connexus and/or its affiliates will be seeking to obtain TCPA Consent so they may make outbound telemarketing calls and/or send text messages to Consenting Users. If the IO specifies that Consenting Users may be contacted via SMS messaging, the TCPA Consent shall include appropriate language to permit use of SMS messaging in compliance with Applicable Laws, the MMA U.S. Consumer Best Practices for Messaging, CTIA SMS Interoperability Guidelines, the CTIA Short Code Monitoring Handbook, and the ATSR (collectively, &ldquo;<strong>Applicable SMS Rules</strong>&rdquo;).</li>
                            <li>The TCPA Consent obtained by the Party responsible for securing such consent (&ldquo;<strong>Responsible Party/ies</strong>&rdquo;) shall contain all of the elements set forth in 47 CFR &sect;64.1200(f)(8) which specifically includes the name of the Party(ies) (each, a &ldquo;<strong>Named Advertiser</strong>&rdquo;) on whose behalf a Consenting User may be called and/or, where applicable, contacted via SMS messaging. Responsible Party shall maintain or cause to be maintained records (&ldquo;<strong>TCPA Records</strong>&rdquo;) of the TCPA Consents obtained from each Consenting User, including the date and time stamp when the TCPA Consent was obtained, the IP address/device ID of Consenting User, the URL for the TCPA Consent form and the contents of the TCPA Consent including Named Advertiser(s), and such other information and/or documentation, that is reasonably necessary to prove in a regulatory or judicial proceeding, or in discussions that could reasonably be expected to predate such a proceeding, that Responsible Party secured legally sufficient TCPA Consent from Consenting User to permit TCPA compliant telemarketing calls and/or contact via SMS messaging, where applicable, by or on behalf of Named Advertiser to such Consenting User. Responsible Party shall maintain the TCPA Records for at least seven (7) years and shall provide such TCPA Records to the requesting Party within ten (10) days of its written request therefor. The Responsible Party is solely responsible for all costs associated with acquiring and maintaining the TCPA Records.</li>
                            <li>If a Party is contacted by a Consenting User that he/she no longer wishes to receive telemarketing calls or SMS messaging, such Party shall immediately add such user to its TCPA consent revocation list, and shall notify the other Party and, where necessary, any Named Advertiser. Each Party shall promptly cease making calls to such consumer, but no later than one (1) business day after the consumer has made such request.</li>
                            <li>Any Party sending SMS messages pursuant to this Agreement shall maintain systems which record the date/time stamps of the messages and the message content, and shall maintain opt-out mechanisms which suppress users who opt-out from receiving future SMS messages.</li>
                        </ul>
                    </li>
                    <li><h2><strong>Compensation.<span className="Apple-converted-space">&nbsp;</span></strong></h2>
                        <ul>
                            <li>Connexus shall pay Publisher fees (the &ldquo;<strong>Fees</strong>&rdquo;) in accordance with the terms of each applicable IO. Publisher shall provide Connexus with a monthly invoice for Fees owed for the month promptly following the end of the month. Unless otherwise provided in the applicable IO, payment shall be due on the thirtieth (30th) following the close of the month in which the Fees were accrued. Payment will be made in U.S. Dollars.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li>Connexus may not be obligated to pay Fees directly related to Invalid Actions, as recorded by Connexus, that have been timely disputed in accordance with this Section. For purposes of this Agreement, an &ldquo;<strong>Invalid Action</strong>&rdquo; means (a) a Lead that is a computer-generated user, such as a robot, spider, computer script or other automated, artificial, or fraudulent method designed to appear like an individual, live person; (b) a Lead for which Publisher has failed to supply the information set forth in the applicable IO; or (c) a Duplicate. For purposes of this Agreement, a &ldquo;<strong>Duplicate</strong>&rdquo; means a Lead that includes an email address, submitted by Publisher to Connexus, that either (y)&nbsp;belongs to an individual that has already accrued an obligation to pay Publisher; or (z) was already submitted within the previous thirty (30) days to Connexus by Publisher, and for which Connexus has already paid Publisher.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li>In order to timely dispute payment for a Lead, Connexus must notify Publisher in writing. The dispute notice shall identify the Leads disputed and the grounds for dispute. The Parties shall use commercially reasonable efforts to resolve disputed Leads.<span className="Apple-converted-space">&nbsp;</span></li>
                        </ul>
                    </li>
                    <li><h2><strong>Term and Termination.</strong></h2>
                        <ul>
                            <li><span className="text-underline">Term</span>. This Agreement shall start on the Effective Date and continue until terminated pursuant to the terms of this Agreement (the &ldquo;<strong>Term</strong>&rdquo;). At the end of the Term, each Party shall pay all compensation validly due under this Agreement.</li>
                            <li><span className="text-underline">Termination Without Cause</span>. Either Party may terminate this Agreement for any reason by providing the other Party with thirty (30) days prior written notice.</li>
                            <li><span className="text-underline">Termination With Cause</span>. Either Party may terminate this Agreement at any time with immediate effect, if the other Party is adjudged insolvent or bankrupt, or if proceedings are instituted by or against the other Party seeking relief, reorganization or arrangement or compromise or settlement under any laws relating to insolvency, or seeking any assignment for the benefit of creditors, or seeking the appointment of a receiver, liquidator or trustee of a Party&rsquo;s property or assets or the liquidation, dissolution or winding up of a Party&rsquo;s business<span>.</span></li>
                            <li><span className="text-underline">Survival</span>. Sections 6 through 11 will survive expiration or termination of this Agreement as well as all other provisions which by their nature are intended to so survive.</li>
                        </ul>
                    </li>
                    <li><h2><strong>Representations and Warranties.<span className="Apple-converted-space">&nbsp;</span></strong></h2>
                        <ul>
                            <li><span className="text-underline">Both Parties</span>. Each Party represents and warrants that (a) it has the authority to enter into this Agreement, (b) its execution of this Agreement and performance of its obligations under this Agreement do not and will not violate any other agreement to which it is a party, and (c) it has sufficient rights to grant any licenses granted, and provide all Services hereunder. Each Party will comply with all Applicable Law, and reasonably cooperate with each other to ensure compliance. As applicable to its respective obligations under this Agreement, each Party will provide notice of a privacy policy detailing its respective data collection, sharing, and use practices that comply with all Applicable Law. Each Party represents, warrants, and covenants that it shall not (without the express written consent of the other Party) directly or indirectly (including via any agents or subcontractors) collect, store, process or use in connection with this Agreement any personal data from any persons residing in or located within the European Economic Area or any other jurisdiction in which the collection, storage, processing or use of personal data would require execution of a separate data processing agreement. If either Party reasonably and in good faith determines that the Parties must execute a separate industry standard Data Processing Agreement (&ldquo;<strong>DPA</strong>&rdquo;), Publisher shall execute Connexus&rsquo;s standard DPA.</li>
                            <li><span className="text-underline">By Publisher</span>.<span className="Apple-converted-space">&nbsp;</span>
                                <ul>
                                    <li>If Publisher chooses to serve Native Ads, Publisher shall include any legally required disclosures applicable to Native Ads (e.g., FTC endorsement disclosures), and ensure that any such disclosure is neither removed nor obscured.<span className="Apple-converted-space">&nbsp;</span></li>
                                    <li>Publisher shall ensure that the Inventory it makes available to Connexus will not introduce viruses or other malware into the Services, systems or end users.<span className="Apple-converted-space">&nbsp;</span></li>
                                    <li>Publisher shall ensure that the Sites do not promote, contain, or link directly to the following types of content: (a)&nbsp;adult, pornographic or any illegal content; (b)&nbsp;tobacco, alcohol, ammunition, hazardous substances, illegal drugs, gore, violence, gambling and/or racist content; (c)&nbsp;pages containing profanity and/or content that discriminates or is offensive to any section of people; (d)&nbsp;hate, violence, racial intolerance, or content that advocates against any individual, group, or organization; (e)&nbsp;the sale of prescription drugs; (f)&nbsp;the sale of counterfeit products, imitations of designer or other goods, stolen items or any products that infringe the intellectual property rights of other parties; (g)&nbsp;pages containing content that infringes the intellectual property rights of other parties; (h)&nbsp;pages containing forums, discussion boards, chat rooms, or any content area that is open to public updates without adequate moderation; (i)&nbsp;content that has been generated using computer programs and hence may not be comprehendible; (j)&nbsp;pages containing primarily user-generated content and/or fake news; and/or (k) any other content that Connexus believes in its sole discretion to be illegal.<span className="Apple-converted-space">&nbsp;</span></li>
                                    <li>In addition, and without limiting the foregoing, Publisher represents, warrants, and covenants that (a) it will not knowingly provide any information to Connexus, including Leads, which were procured in a manner not in compliance with Applicable Laws; (b) it has established and implemented commercially reasonable practices and procedures designed to prevent the generation of Leads that do not comply with Applicable Laws; (c) it will not knowingly purchase or resell Leads that have originated from or have been generated by unsolicited commercial email, text messages or advertisements; (d)&nbsp;all pages where Publisher collects Leads, pursuant to the Agreement, will contain a prominent link to a privacy policy, which permits the transfer of the Leads to Connexus, as provided for herein; (e)&nbsp;it will not, except as otherwise provided in an IO, edit or otherwise modify any Creative that has been supplied by Connexus without Connexus&rsquo;s prior consent (email to suffice); (f)&nbsp;all pages where Publisher or its affiliates collect consumer information by or through any campaign pursuant to the Agreement, contain a prominent link to Publisher&rsquo;s privacy policy, which shall be legally compliant, truthful, accurate and sufficient in every respect; and (g) will not load any computer program onto a consumer&rsquo;s computer, in connection with a campaign, without Connexus&rsquo;s prior approval and the consumer&rsquo;s express consent after receiving clear and conspicuous notice about the nature of the application to be downloaded, such as in the case of Leads sold on a CPI basis.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><h2><strong>Licenses and Ownership.</strong></h2>
                        <ul>
                            <li>As between Connexus and Publisher, Publisher owns all right, title and interest in (a) content created by Publisher; and (b) any goodwill, derivative works, improvements and/or intellectual property rights associated with the foregoing (&ldquo;<strong>Publisher Data</strong>&rdquo;). Publisher Data shall not include any data provided or made available by Connexus. Publisher Data shall not include any data provided or made available by Connexus and/or its affiliates, including but not limited to the Creatives.</li>
                            <li>As between Connexus and Advertiser, Connexus owns all right, title and interest in (a) the Services; (b) Connexus&rsquo;s reports and targeting strategies; (c)&nbsp;Connexus&rsquo;s proprietary data validation filters, lead-distribution technology and all related code, software, and data (collectively, the &ldquo;<strong>Technology</strong>&rdquo;); and (d)&nbsp;any goodwill, derivative works, improvements and/or intellectual property associated with the foregoing (&ldquo;<strong>Connexus Data</strong>&rdquo;). During the Term, Connexus grants Publisher a non-exclusive, non-transferable (except in connection with any assignment permitted hereunder), non-sublicensable, fully revocable license to use the Services and access the Technology, solely as described in this Agreement. In addition, any of Publisher&rsquo;s suggestions, enhancement requests or feedback may be used by Connexus without compensation, accounting or attribution to Publisher.</li>
                            <li>Connexus retains all right, title and interest in all Connexus Data. Publisher acknowledges that Connexus and/or its affiliates may drop cookies on its users in connection with the Services. Publisher hereby grants Connexus a limited, sublicensable, royalty-free right to collect, use and share user data (including Publisher Data) that is passed by Publisher or collected by Connexus and/or its affiliates solely (a) in connection with the delivery and optimization of the Services during the Term, (b)&nbsp;for reporting purposes during and after the Term, and (c) in response to a legal demand or process during and after the Term.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li>Publisher will not (and will not allow others to) copy, modify, reverse engineer, distribute, sell, or lease the Creatives or Connexus Data, attempt to unlock or bypass any encryption or other protections used by Connexus or any initialization system, and will not provide Creatives or Connexus Data or access to the Technology to any third party. Publisher acknowledges that any non-public data regarding the Services or Technology (including any reports provided by Connexus to Publisher and information related to the performance of the Services) are Connexus&rsquo;s Confidential Information.</li>
                        </ul>
                    </li>
                    <li><h2><strong>Disclaimers.</strong></h2> EXCEPT AS OTHERWISE PROVIDED HEREIN, CONNEXUS PROVIDES THE SERVICES &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;. OTHER THAN AS EXPRESSLY PROVIDED HEREIN, CONNEXUS MAKES NO GUARANTEE, REPRESENTATION OR WARRANTY REGARDING THE LEADS (INCLUDING BUT NOT LIMITED TO THE AMOUNT, ACCURACY, TIMING, CREDIT WORTHINESS, OR QUALITY), RELATED INFORMATION, THE SERVICES, AND/OR ANY OTHER INFORMATION PROVIDED UNDER THIS AGREEMENT. TO THE EXTENT PERMITTED BY LAW, CONNEXUS DISCLAIMS ALL WARRANTIES, EXPRESS, STATUTORY OR IMPLIED, INCLUDING WARRANTIES OF NONINFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, CONNEXUS DOES NOT WARRANT THAT THE SERVICES WILL BE ERROR-FREE, DEFECTS WILL BE CORRECTED, THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL MAINTAIN A CERTAIN UPTIME, RESPONSE TIME OR AVAILABILITY. These disclaimers are an essential basis of the bargain between the Parties.</li>
                    <li><h2><strong>Indemnification.</strong></h2>
                        <ul>
                            <li>Connexus will indemnify, defend and hold harmless Publisher, its affiliates, officers, directors, employees, contractors, licensors, licensees and agents (&ldquo;<strong>Publisher Covered Entities</strong>&rdquo;), against any third-party claims or liabilities (including reasonable attorneys&rsquo; fees) arising out of or related to (a)&nbsp;a claim that the Technology infringes or misappropriates any copyright, trade secret, or trademark of that third party; (b) Connexus&rsquo;s violation of law, negligence or willful misconduct; and/or (c) Connexus&rsquo;s breach of the representations and/or warranties contained in Section 5 of this Agreement. In connection with any demand for indemnity under this Section, (y)&nbsp;Publisher will promptly notify Connexus in writing of the claim; and (z) Connexus will retain sole control of the defense and/or settlement of the claim (provided that Connexus shall not enter into any settlement without the prior written consent of Publisher, not to be unreasonably withheld).</li>
                            <li>Notwithstanding Section 9.1, Connexus will have no obligation to indemnify, defend or hold harmless any Publisher Covered Entity with respect to any claim that one or more Leads lacked TCPA Consent (each a &ldquo;<strong>TCPA Claim</strong>&rdquo;), provided that Publisher has not objected in writing to the manner in which Connexus collects Leads and/or obtains a complaining user&rsquo;s TCPA Consent.</li>
                            <li>Publisher will indemnify, defend and hold harmless Connexus, its affiliates, officers, directors, employees, contractors, licensors, licensees, agents, and partners (&ldquo;<strong>Connexus Covered Entities</strong>&rdquo;) against any third-party claims or liabilities (including reasonable attorneys&rsquo; fees) arising out of or related to (a)&nbsp;the Sites, or Publisher&rsquo;s trademarks, products or services; (b)&nbsp;Publisher&rsquo;s violation of law, negligence or willful misconduct; (c) Publisher&rsquo;s use of the Leads; and/or (d) Publisher&rsquo;s breach of the representations and/or warranties contained in Section 5 of this Agreement. In connection with any demand for indemnity under this Section, (x) Connexus will promptly notify Publisher in writing of the claim; (y) Connexus will retain sole control of the defense and/or settlement of the claim (provided that Connexus shall not enter into any settlement without the prior written consent of Publisher, not to be unreasonably withheld); and (z) Publisher will provide Connexus, at Publisher&rsquo;s expense, all reasonable assistance, information and authority reasonably required for the defense and/or settlement of the claim.</li>
                        </ul>
                    </li>
                    <li><h2><strong>LIMITATION OF LIABILITY.</strong></h2> EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL OR INCIDENTAL DAMAGES, INCLUDING ANY DAMAGES RELATING TO LOST BUSINESS, GOODWILL, DATA AND/OR PROFITS, ARISING FROM OR RELATING TO THIS AGREEMENT. EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, CONNEXUS&rsquo;S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT, WHETHER IN CONTRACT OR TORT OR OTHERWISE, SHALL NOT EXCEED THE AMOUNT OF FEES THAT CONNEXUS ACTUALLY RETAINED UNDER THIS AGREEMENT IN THE SIX (6) MONTHS PRECEDING THE CIRCUMSTANCES THAT GAVE RISE TO THE CLAIM AT ISSUE. THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE THIS AMOUNT. THE LIMITATION OF LIABILITY SET FORTH IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.<span className="Apple-converted-space">&nbsp;</span></li>
                    <li><h2><strong>Confidential Information.</strong></h2>
                        <ul>
                            <li><span className="text-underline">Confidential Information</span>. &ldquo;<strong>Confidential Information</strong>&rdquo; means this Agreement and associated discussions, as well as information concerning the Services, the Leads; Connexus&rsquo;s financial affairs, partnerships, marketing plans or strategies, current or future business opportunities, technology, customer relationships, and contact lists; and any and all information that is disclosed by either Party to the other Party, either directly or indirectly, in writing, orally or by inspection of tangible objects, which if disclosed in writing or tangible form is marked as &ldquo;Confidential,&rdquo; or with some similar designation, or if disclosed orally, is identified as being proprietary and/or confidential at the time of disclosure, or under the circumstances and nature of the information should be reasonably deemed confidential.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li><span className="text-underline">Use and Disclosure Restrictions</span>. Neither Party shall use the other Party&rsquo;s Confidential Information except as necessary to exercise its rights or perform its obligations under this Agreement. Each Party shall not disclose the other Party&rsquo;s Confidential Information to any third party except to those of its employees, subcontractors, and advisors that need to know such Confidential Information for the purposes of this Agreement, provided that each such employee and subcontractor is subject to confidentiality obligations that are at least as protective of Confidential Information as those set forth herein. Each Party will use all reasonable efforts to maintain the confidentiality of all Confidential Information of the other Party in its possession or control, but in no event less than the efforts that Party ordinarily uses with respect to its own proprietary information of similar nature and importance. The foregoing obligations will not restrict either Party from disclosing Confidential Information of the other Party (a) pursuant to the order or requirement of a court, administrative agency, or other governmental body, provided that the Party required to make such a disclosure gives reasonable notice to the other Party to contest such order or requirement, if possible; or (b) on an as-needed, confidential basis to its legal or financial advisors. In addition, each Party may disclose the terms and conditions of this Agreement as required under applicable securities regulations on a confidential basis to current or prospective investors or acquirers of such Party; provided that, the other Party is allowed to redact the Agreement.</li>
                            <li><span className="text-underline">Exceptions</span>. The foregoing obligations will not apply to any information that (a) is or becomes generally known to the public through no fault of or breach of this Agreement by the receiving Party; (b) is rightfully known by the receiving Party at the time of disclosure without an obligation of confidentiality; (c) is independently developed by the receiving Party without use of the disclosing Party&rsquo;s Confidential Information; or (d) the receiving Party rightfully obtains from a third party without an obligation of confidentiality.</li>
                            <li><span className="text-underline">Injunctive Relief</span>. The Parties agree that any breach of this Section will cause irreparable harm to the disclosing Party for which monetary damages will be inadequate. Accordingly, the disclosing Party will be entitled to seek, and, if granted, obtain and enforce injunctive or other equitable relief (in addition to any other remedies available to it) to remedy any threatened or actual breach of this Section.</li>
                        </ul>
                    </li>
                    <li><h2><strong>Miscellaneous.</strong></h2>
                        <ul>
                            <li><span className="text-underline">Assignment</span>. Neither Party may assign or otherwise transfer any of its rights or obligations under this Agreement without the prior, written consent of the other Party; provided, however, that a Party may, upon written notice to the other Party and without the consent of the other Party, assign or otherwise transfer this Agreement in connection with a change of control transaction (whether by merger, consolidation, sale of equity interests, sale of all or substantially all assets, or otherwise), provided that in all cases, the assignee or transferee agrees in writing to be bound by the terms and conditions of this Agreement. Any assignment or other transfer in violation of this Section will be null and void. Subject to the foregoing, this Agreement will be binding upon and inure to the benefit of the Parties hereto and their permitted successors and assigns.</li>
                            <li><span className="text-underline">Independent Contractors</span>. The Parties are independent contractors. Neither Party shall be deemed to be an employee, agent, partner, joint venturer, or legal representative of the other for any purpose, and neither shall have any right, power, or authority to create any obligation or responsibility on behalf of the other.</li>
                            <li><span className="text-underline">No Publicity</span>. Neither Party will issue any press releases regarding this Agreement or use the other Party&rsquo;s name or logo in any materials without the other Party&rsquo;s prior written consent.</li>
                            <li><span className="text-underline">No Disparagement</span>. Neither Party will make statements or representations, or otherwise communicate, directly or indirectly, in writing, orally or otherwise, or take any action which may, directly or indirectly, disparage or be damaging to the other Party (including either of the Party&rsquo;s subsidiaries, affiliates, officers, directors, employees, partners or stockholders); provided that nothing in this Section shall preclude either Party from making truthful statements or disclosures that are required by applicable law, regulation or legal process.</li>
                            <li><span className="text-underline">Waiver</span>. No failure or delay by either Party in exercising any right or remedy under this Agreement shall operate or be deemed as a waiver of any such right or remedy.</li>
                            <li><span className="text-underline">Governing Law/Forum Selection</span>. This Agreement shall be governed by the laws of the State of California, without regard to the conflicts of law rules of such state. Any dispute that arises under or relates to this Agreement (whether in contract, tort or both) commenced by either Party shall be resolved in state or federal court in San Francisco County, California, and the Parties expressly waive any right they may otherwise have to cause any such action or preceding to be brought or tried elsewhere.</li>
                            <li><span className="text-underline">Severability</span>. If any provision of this Agreement is found invalid, illegal or unenforceable by a court of competent jurisdiction, that provision shall be amended to achieve as nearly as possible the original intentions of the Parties, and the remainder of this Agreement shall remain in full force and effect.<span className="Apple-converted-space">&nbsp;</span></li>
                            <li><span className="text-underline">Complete Understanding</span>. This Agreement, including any Exhibits, constitutes the final and complete agreement between the Parties regarding the subject matter hereof, and supersedes any prior or contemporaneous communications, representations or agreements between the Parties, whether oral or written.</li>
                            <li><span className="text-underline">Force Majeure</span>. Neither Party shall be deemed to be in breach of this Agreement for any failure or delay in performance caused by reasons beyond its reasonable control, including but not limited to acts of God, earthquakes, strikes or shortages of materials.</li>
                            <li><span className="text-underline">Headings</span>. The division of this Agreement into sections, subsections and other subdivisions, and the insertion of headings are for convenience of reference only and shall not affect or be used in the construction or interpretation of this Agreement.</li>
                            <li><span className="text-underline">Notices</span>. All notices required under this Agreement must be in writing. Notices shall be effective upon (a) actual delivery to the other Party, if delivered in person, or by email, facsimile, or national overnight courier; or (b) five (5) business days after being mailed to the other Party via U.S. postal service, postage prepaid. All notices shall be sent to the address stated in the introductory paragraph of this Agreement or at such other address as either Party may provide by advance written notice.</li>
                            <li><span className="text-underline">Counterparts</span>. This Agreement may be executed in counterparts, which may be exchanged by facsimile or PDF, each of which shall be deemed an original, but which together shall constitute one and the same instrument.</li>
                        </ul>
                    </li>
                </ul>
                <p>By executing this Agreement, the Parties acknowledge that they have read and understood the foregoing terms and agree to be legally bound by the same. The Parties hereby cause this Agreement to be executed by their duly authorized representatives and made effective as of the Effective Date.</p>

                <table id="publisher-table">
                    <tr>
                        <th colSpan={2}>Connexus</th>
                        <th colSpan={2}>Publisher</th>
                    </tr>
                    <tr>
                        <td className="width30">Signed: </td>
                        <td className="width70"></td>
                        <td className="width30">Signed:</td>
                        <td className="width70"></td>
                    </tr>
                    <tr>
                        <td className="width30">Name: </td>
                        <td className="width70">Alberto Moraes</td>
                        <td className="width30">Name:</td>
                        <td className="width70"></td>
                    </tr>
                    <tr>
                        <td className="width30">Title: </td>
                        <td className="width70">CEO</td>
                        <td className="width30">Title:</td>
                        <td className="width70"></td>
                    </tr>
                    <tr>
                        <td className="width30">Date: </td>
                        <td className="width70"></td>
                        <td className="width30">Date:</td>
                        <td className="width70"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
);

export default TermsAndConditions;
