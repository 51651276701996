import React from 'react';
import { CircularProgress } from '@material-ui/core';
import './submitButton.css';

interface ButtonProps {
    label: string;
    showLoader: boolean;
    onClick: () => void;
}

const SubmitButton: React.FC<ButtonProps> = ({
    label,
    showLoader,
    onClick,
}: ButtonProps) => (
    <div className="submit-btn" onClick={onClick}>
        <img className="btn-logo" src="/images/logo.svg" alt="cr" />
        <div className="btn-txt">{label}</div>
        {
            showLoader && <CircularProgress />
        }
    </div>
);

export default SubmitButton;
