import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Color } from '@material-ui/lab';

interface ToastState {
    message: string;
    show: boolean;
    type: Color;
}

const initState: ToastState = {
    message: '',
    show: false,
    type: 'success',
};

const toastSlice = createSlice({
    name: 'toast',
    initialState: initState,
    reducers: {
        setToast: (state, action: PayloadAction<Partial<ToastState>>): void => {
            state.message = action.payload.message ? action.payload.message : '';
            state.type = action.payload.type ? action.payload.type : 'success';
            state.show = true;
        },
        resetToast: (state): void => {
            state.message = '';
            state.show = false;
        },
    },
});

export const { setToast, resetToast } = toastSlice.actions;

export default toastSlice.reducer;
