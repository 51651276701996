import { isValidEmail } from 'utils';
import { SignUpDetails } from './signUp';

const validationRules = {
    publisherName: [
        (value: SignUpDetails): string | null => ((value.publisherName
            && value.publisherName.length) ? null : 'Publisher Name is required'),
    ],
    emailId: [
        (value: SignUpDetails): string | null => ((value.emailId && value.emailId.length)
            ? null : 'Email Id is required'),
        (value: SignUpDetails): string | null => ((value.emailId && isValidEmail(value.emailId))
            ? null : 'Email Id is invalid'),
    ],
    password: [
        (value: SignUpDetails): string | null => ((value.password && value.password.length)
            ? null : 'Password is required'),
        (value: SignUpDetails): string | null => ((value.password) && value.password.length > 7
            ? null : 'Password must be atleast 8 characters long'),
    ],
    optIn: [
        (value: SignUpDetails): string | null => (value.optIn
            ? null : 'Please agree to terms and conditions before signing up'),
    ],
};

export default validationRules;
