import firebase from 'firebase/app';
import firebaseConfig from './firebaseConfig';
import 'firebase/auth';
import 'firebase/database';

firebase.initializeApp(firebaseConfig);

export const database = firebase.database();

export default firebase;
