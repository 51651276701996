import React from 'react';
import {
    TextField,
    Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    NavLink,
    Redirect,
    RouteComponentProps,
} from 'react-router-dom';
import useForm from 'hooks/formHook';
import SubmitButton from 'components/loginSubmitButton';
import { RootState, AppDispatch } from 'state/store';
import { signUpAuth } from 'state/firebaseSlice';
import { formatPhoneNumber } from 'utils';
import validationRules from './validationRules';
import './signUp.css';

export interface SignUpDetails {
    publisherName: string;
    emailId: string;
    phoneNumber: string;
    password: string;
    optIn: boolean;
}

const initState: SignUpDetails = {
    publisherName: '',
    emailId: '',
    phoneNumber: '',
    password: '',
    optIn: false,
};

interface StateProps {
    isSigningUp: boolean;
    isAuthenticated: boolean;
}

interface DispatchProps {
    signUp: (signUpDetails: SignUpDetails) => void;
}

type LoginProps = StateProps & DispatchProps & RouteComponentProps;

const Login: React.FC<LoginProps> = ({
    isSigningUp,
    isAuthenticated,
    signUp,
}: LoginProps) => {
    const onSignIn = (formState: SignUpDetails): void => {
        signUp(formState);
    };

    const {
        state,
        errors,
        handleOnChange,
        handleOnBlur,
        handleOnSubmit,
    } = useForm<SignUpDetails>({
        initialValues: initState,
        validationRules,
        submit: onSignIn,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { id, value } = event.target;
        handleOnChange(id, value);
    };

    const handleInputBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        const { id } = event.target;
        handleOnBlur(id);
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void => {
        const { id } = event.target;
        handleOnChange(id, checked);
    };

    if (isAuthenticated) {
        return (
            <Redirect
                to="/thankyou"
            />
        );
    }

    return (
        <div className="signup-wrapper container col-xs-12">
            <div className="row signup-block">
                <div className="col-sm-6 signup-left">
                    <div className="sign-in-txt">
                        Sign up for Publisher Dashboard
                    </div>
                    <form className="sign-up-form-wrapper">
                        <div className="form-input-wrapper">
                            <TextField
                                error={Boolean(errors.publisherName)}
                                variant="outlined"
                                id="publisherName"
                                label="Publisher Name*"
                                helperText={
                                    errors.publisherName
                                    && errors.publisherName
                                }
                                className="form-input"
                                value={state.publisherName}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <TextField
                                error={Boolean(errors.emailId)}
                                variant="outlined"
                                id="emailId"
                                label="Email Id*"
                                helperText={
                                    errors.emailId
                                    && errors.emailId
                                }
                                className="form-input"
                                value={state.emailId}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <TextField
                                error={Boolean(errors.phoneNumber)}
                                variant="outlined"
                                type="tel"
                                id="phoneNumber"
                                label="Phone Number*"
                                helperText={
                                    errors.phoneNumber
                                    && errors.phoneNumber
                                }
                                className="form-input"
                                value={formatPhoneNumber(state.phoneNumber)}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <TextField
                                error={Boolean(errors.password)}
                                variant="outlined"
                                type="password"
                                id="password"
                                label="Password*"
                                helperText={
                                    errors.password
                                    && errors.password
                                }
                                className="form-input"
                                value={state.password}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <div className="form-input-wrapper opt-in-block">
                            <div className="opt-in-wrapper">
                                <div className="opt-in-input-wrapper">
                                    <Checkbox
                                        checked={state.optIn}
                                        id="optIn"
                                        color="primary"
                                        onChange={handleCheckboxChange}
                                    />
                                    <p>
                                        I agree to&nbsp;
                                        <NavLink
                                            to="/terms_and_conditions"
                                            target="_blank"
                                        >
                                            terms and conditions
                                        </NavLink>
                                    </p>
                                </div>
                                {
                                    Boolean(errors.optIn)
                                    && <p className="opt-in-error">{errors.optIn}</p>
                                }
                            </div>
                        </div>
                        <SubmitButton
                            label="Sign Up"
                            showLoader={isSigningUp}
                            onClick={handleOnSubmit}
                        />
                        <div className="bottom-actions-wrapper">
                            <NavLink className="sign-up-link" to="/login">
                                Already have an account? Sign In
                            </NavLink>
                        </div>
                    </form>
                </div>
                <div className="col-sm-6 signup-right">
                    <div className="signup-illustration">
                        <img src="/images/login-img.png" alt="signup-img" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    isSigningUp: state.auth.isSigningUp,
    isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    signUp: (signUpDetails: SignUpDetails): void => { dispatch(signUpAuth(signUpDetails)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
