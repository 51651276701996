import React from 'react';
import {
    TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    NavLink,
    Redirect,
    RouteComponentProps,
} from 'react-router-dom';
import useForm from 'hooks/formHook';
import SubmitButton from 'components/loginSubmitButton';
import { RootState, AppDispatch } from 'state/store';
import { loginAuth, LoginCredentials } from 'state/firebaseSlice';
import validationRules from './validationRules';
import './login.css';

const initState: LoginCredentials = {
    emailId: '',
    password: '',
};

interface StateProps {
    isLoggingIn: boolean;
    isAuthenticated: boolean;
}

interface DispatchProps {
    login: (credentials: LoginCredentials) => void;
}

type LoginProps = StateProps & DispatchProps & RouteComponentProps;

const Login: React.FC<LoginProps> = ({
    location,
    isLoggingIn,
    isAuthenticated,
    login,
}: LoginProps) => {
    const onSignIn = (formState: LoginCredentials): void => {
        login(formState);
    };

    const {
        state,
        errors,
        handleOnChange,
        handleOnBlur,
        handleOnSubmit,
    } = useForm<LoginCredentials>({
        initialValues: initState,
        validationRules,
        submit: onSignIn,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { id, value } = event.target;
        handleOnChange(id, value);
    };

    const handleInputBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        const { id } = event.target;
        handleOnBlur(id);
    };

    const { from }: any = location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
        return (
            <Redirect
                to={from}
            />
        );
    }

    return (
        <div className="login-wrapper container col-xs-12">
            <div className="row login-block">
                <div className="col-sm-6 login-left">
                    <div className="sign-in-txt">
                        Sign in to Publisher Dashboard
                    </div>
                    <form className="sign-in-form-wrapper">
                        <div className="form-input-wrapper">
                            <TextField
                                error={Boolean(errors.emailId)}
                                variant="outlined"
                                id="emailId"
                                label="Email Id*"
                                helperText={
                                    errors.emailId
                                    && errors.emailId
                                }
                                className="form-input"
                                value={state.emailId}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <TextField
                                error={Boolean(errors.password)}
                                variant="outlined"
                                type="password"
                                id="password"
                                label="Password*"
                                helperText={
                                    errors.password
                                    && errors.password
                                }
                                className="form-input"
                                value={state.password}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <SubmitButton
                            label="Sign In"
                            showLoader={isLoggingIn}
                            onClick={handleOnSubmit}
                        />
                        <div className="bottom-actions-wrapper">
                            <NavLink className="sign-up-link" to="/signup">
                                Don&apos;t have an account? Sign Up
                            </NavLink>
                        </div>
                    </form>
                </div>
                <div className="col-sm-6 login-right">
                    <div className="login-illustration">
                        <img src="/images/login-img.png" alt="login-img" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    isLoggingIn: state.auth.isLoggingIn,
    isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    login: (credentials: LoginCredentials): void => { dispatch(loginAuth(credentials)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
