import { useReducer, useState } from 'react';
import {
    isEmptyObj,
    constructObjectWithValues,
    executeValidationRules,
    executeAllValidationRules,
} from 'utils';

interface UseForm<T> {
    state: T;
    errors: {[x: string]: string | null};
    setFormState: (newState: T) => void;
    handleOnChange: (id: string, value: any) => void;
    handleOnBlur: (id: string) => void;
    handleOnSubmit: () => void;
}

interface UseFormProps<T> {
    initialValues: T;
    validationRules: {[x: string]: any};
    submit?: (state: T, resetForm: () => void) => void;
    processValue?: (value: any) => any;
}

const reducer = (state: any, action: any): any => {
    switch (action.type) {
    case 'setField':
        return { ...state, [action.payload.id]: action.payload.value };
    case 'setState':
        return { ...state, ...action.payload.value };
    default:
        throw new Error();
    }
};

const useForm = <T>({
    initialValues,
    validationRules,
    submit,
    processValue,
}: UseFormProps<T>): UseForm<T> => {
    const [state, dispatch] = useReducer(reducer, initialValues);
    const [touched, setTouched] = useState(
        constructObjectWithValues<boolean>(validationRules, false),
    );
    const [errors, setErrors] = useState(
        constructObjectWithValues<string | null>(validationRules, null),
    );

    const resetFormState = (): void => {
        dispatch({ type: 'setState', payload: { value: initialValues } });
    };

    const setFormState = (newState: T): void => {
        dispatch({ type: 'setState', payload: { value: newState } });
    };

    const handleOnChange = (id: string, value: any): void => {
        if (typeof processValue === 'function') {
            value = processValue(value);
        }
        dispatch({ type: 'setField', payload: { id, value } });
        if (!isEmptyObj(errors) && errors[id]) {
            setErrors({
                ...errors,
                [id]: executeValidationRules(
                    validationRules[id],
                    { ...state, [id]: value },
                ),
            });
        }
    };

    const handleOnBlur = (id: string): void => {
        setTouched({ ...touched, [id]: true });
        setErrors({
            ...errors,
            [id]: executeValidationRules(validationRules[id], state),
        });
    };

    const handleOnSubmit = (): void => {
        if (!isEmptyObj(errors)) {
            const { errorsObj, hasError } = executeAllValidationRules(validationRules, state);
            setErrors(errorsObj);
            if (!hasError && submit) {
                submit(state, resetFormState);
            }
        } else if (submit) {
            submit(state, resetFormState);
        }
    };

    return {
        state,
        errors,
        setFormState,
        handleOnChange,
        handleOnBlur,
        handleOnSubmit,
    };
};

export default useForm;
