import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAuth from 'common/auth';
import firebase, { createPublisher } from 'firebaseAuth';
import { SignUpDetails } from 'pages/signUp';
import { postFlock } from 'utils/flock';
import { setToast } from './toast';

interface User {
    email: string;
}

export interface LoginCredentials {
    emailId: string;
    password: string;
}

interface AuthState {
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    isSigningUp: boolean;
    isVerifying: boolean;
    loginError: boolean;
    logoutError: boolean;
    signUpError: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

const initState: AuthState = {
    isLoggingIn: false,
    isLoggingOut: false,
    isSigningUp: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    signUpError: false,
    isAuthenticated: false,
    user: null,
};

export const verifyAuth = createAsyncThunk<User>(
    'auth/verify',
    (_, { dispatch }) => new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
            if (user) {
                userAuth.setUser(user);
                const { email } = user;
                if (email) {
                    resolve({ email });
                }
            }
            resolve();
        },
        (err: any) => {
            dispatch(setToast({
                type: 'error',
                message: 'Authentication error',
            }));
            reject(err);
        });
    }),
);

export const signUpAuth = createAsyncThunk(
    'auth/signup',
    async (signUpDetails: SignUpDetails, { dispatch }): Promise<any> => {
        try {
            const user = await createPublisher(signUpDetails);
            await postFlock(signUpDetails);
            if (user && user.email) {
                return { email: user.email };
            }
        } catch (err) {
            if (err && err.code && err.code.startsWith('auth') && err.message) {
                dispatch(setToast({
                    type: 'error',
                    message: err.message,
                }));
            }
            throw new Error(err);
        }
        return null;
    },
);

export const loginAuth = createAsyncThunk(
    'auth/login',
    async (credentials: LoginCredentials, { dispatch }): Promise<User | null> => {
        try {
            const { emailId, password } = credentials;
            const result: firebase.auth.UserCredential | null = await firebase
                .auth().signInWithEmailAndPassword(emailId, password);
            if (result && result.user) {
                userAuth.setUser(result.user);
                const { email } = result.user;
                if (email) {
                    return { email };
                }
            }
        } catch (err) {
            if (err && err.code && err.code.startsWith('auth') && err.message) {
                dispatch(setToast({
                    type: 'error',
                    message: err.message,
                }));
            }
            throw new Error(err);
        }
        return null;
    },
);

export const logoutAuth = createAsyncThunk(
    'auth/logout',
    () => firebase.auth().signOut(),
);

const authSlice = createSlice({
    name: 'auth',
    initialState: initState,
    reducers: {},
    extraReducers: {
        [verifyAuth.fulfilled.type]: (state, action): void => {
            state.isVerifying = false;
            if (action.payload) {
                state.isAuthenticated = true;
                state.user = action.payload;
            } else {
                state.isAuthenticated = false;
            }
        },
        [verifyAuth.pending.type]: (state): void => {
            state.isVerifying = true;
        },
        [verifyAuth.rejected.type]: (state): void => {
            state.isVerifying = false;
            state.isAuthenticated = false;
            state.loginError = true;
        },
        [loginAuth.pending.type]: (state): void => {
            state.isLoggingIn = true;
            state.loginError = false;
        },
        [loginAuth.fulfilled.type]: (state, action): void => {
            state.isLoggingIn = false;
            if (action.payload) {
                state.isAuthenticated = true;
                state.user = action.payload;
            } else {
                state.isAuthenticated = false;
            }
        },
        [loginAuth.rejected.type]: (state): void => {
            state.isLoggingIn = false;
            state.isAuthenticated = false;
            state.loginError = true;
        },
        [logoutAuth.pending.type]: (state): void => {
            state.isLoggingOut = true;
            state.logoutError = false;
        },
        [logoutAuth.fulfilled.type]: (state): void => {
            state.isLoggingOut = false;
            state.isAuthenticated = false;
            state.user = null;
        },
        [logoutAuth.rejected.type]: (state): void => {
            state.isLoggingOut = false;
            state.isAuthenticated = true;
            state.logoutError = true;
        },
        [signUpAuth.pending.type]: (state): void => {
            state.isSigningUp = true;
            state.signUpError = false;
        },
        [signUpAuth.fulfilled.type]: (state, action): void => {
            state.isSigningUp = false;
            if (action.payload) {
                state.isAuthenticated = true;
                state.user = action.payload;
            } else {
                state.isAuthenticated = false;
            }
        },
        [signUpAuth.rejected.type]: (state): void => {
            state.isSigningUp = false;
            state.isAuthenticated = false;
            state.signUpError = true;
        },
    },
});

export default authSlice.reducer;
