import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from 'components/protectedRoute';
import HeaderComponent from 'components/header';
import Toast from 'components/toast';
import Login from 'pages/login';
import Home from 'pages/home';
import SignUp from 'pages/signUp';
import ThankYou from 'pages/thankYou';
import TermsAndConditions from 'pages/termsAndConditions';
import { logoutAuth } from 'state/firebaseSlice';
import { RootState, AppDispatch } from 'state/store';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

interface StateProps {
    emailId: string | null;
}

interface DisptachProps {
    logout: () => void;
}

type AppProps = StateProps & DisptachProps;

function App({ emailId, logout }: AppProps): JSX.Element {
    return (
        <div className="consumer-research-wrapper">
            <HeaderComponent
                emailId={emailId}
                logout={logout}
            />
            <Toast />
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={SignUp} />
                <Route
                    exact
                    path="/terms_and_conditions"
                    component={TermsAndConditions}
                />
                <ProtectedRoute
                    exact
                    path="/"
                    component={Home}
                />
                <ProtectedRoute
                    exact
                    path="/thankyou"
                    component={ThankYou}
                />
            </Switch>
        </div>
    );
}

const mapStateToProps = (state: RootState): StateProps => ({
    emailId: state.auth.user ? state.auth.user.email : null,
});

const mapDisptachToProps = (dispatch: AppDispatch): DisptachProps => ({
    logout: (): void => { dispatch(logoutAuth()); },
});

export default connect(mapStateToProps, mapDisptachToProps)(App);
