import React from 'react';
import './home.css';

const Home: React.FC = () => (
    <div className="reports-page">
        Placeholder for reports page
    </div>
);

export default Home;
