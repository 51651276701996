const UrlConstants = {
    BASE_URL: {
        FLOCK: {
            SURVEY: 'https://api.flock.com/hooks/sendMessage/8106affd-9301-45dc-b5be-2dc5bb9da1d1',
            PUBLISHER_ALERTS: 'https://api.flock.com/hooks/sendMessage/223770ad-73f4-4e2d-862e-7ca2cf6aeb46',
        },
    },
};

export default UrlConstants;
